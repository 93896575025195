import myTransform from '@/libs_sz/utils/transform'
export default {
  data() {
    return {
      factoryList: [],
      workshopList: [],
      workshoplist: [],
      warehouseList: [],
      warehouselist: [],
      warehouseArealist: [],
      warehouseAreaList:[],
      areaList: [],
      arealist: [],
      productionLineList: [],
      productionLinelist: [],
      deviceList: [],
      devicelist: [],
      factoryPositionList: [],
      factoryPositionlist: [],
      deviceGoodsPositionList: [],
      deviceGoodsPositionlist: [],
      turnoverContainerTypeList: [],
      turnoverContainerCodeList: [],
      turnoverContainerCodelist: [],
      moveContainerTypeList: [],
      classifySecondList: [],
      materialClassifySecondList:[],
      materialCodeList: [],
      materialCodelist: [],
    }
  },

  methods: {
    initSelectList() {
      this.$store.dispatch('base/getGoodsPositionTypeList')
      this.$store.dispatch('base/getReceiptTypeList')
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
      this.getClassifyFirst()
      this.getTurnoverContainerTypeList()
      this.getMoveContainerTypeList()
      this.getClassifyByFirstClassify()
      this.getMaterialCodeList()
      this.getFactoryList()
      this.getWorkshopList()
      this.getWarehouseList()
      this.getAreaList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
      this.getProductionLineList()
      this.getDeviceList()
      this.getDeviceGoodsPositionList()
    },
    /**
     * 根据下拉框value值获取label
     * @param {*} list
     * @param {*} key
     */
    getOptionsLabel(key = '', item = {}) {
      return this[`${key}List`]?.find(t => t.value === item[key])?.label ?? ''
    },
    /**
     * 获取工厂列表
     */
    async getFactoryList(params = {}) {
      this.getSelectOptions('getFactoryDictList', params).then((list) => (this.factoryList = list))
    },
    /**
     * 获取车间列表
     */
    getWorkshopList(params) {
      this.getSelectOptions('getWorkshopDictList', params).then((list) => {
        this.workshopList = list
        if(!params) this.workshoplist = list
      })
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList(params) {
      this.getSelectOptions('getWarehouseDictList', params).then((list) => {
        this.warehouseList = list
        if(!params) this.warehouselist = list
      })
    },
    /**
     * 获取库区列表
     */
    getWarehouseAreaList(params) {
      this.getSelectOptions('getWarehouseAreaDictList', params).then((list) => {
        this.warehouseAreaList = list
        if(!params) this.warehouseArealist = list
      })
    },
    /**
     * 获取区域列表
     */
    getAreaList(params) {
      this.getSelectOptions('getAreaDictList', params).then((list) => {
        this.areaList = list
        if(!params) this.arealist = list
      })
    },
    /**
     * 获取产线列表
     */
    getProductionLineList(params) {
      this.getSelectOptions('getProductionLineDictList', params).then((list) => {
        this.productionLineList = list
        if(!params) this.productionLinelist = list
      })
    },
    /**
     * 获取设备列表
     */
    getDeviceList(params) {
      this.getSelectOptions('getDeviceDictList', params).then((list) => {
        this.deviceList = list
        if(!params) this.devicelist = list
      })
    },
   /**
     * 获取设备货位列表
     */
    getDeviceGoodsPositionList(params) {
      this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), {...params, goodsPositionType: 'deviceGoodsPositionSec' }).then(({ data }) => {
        this.deviceGoodsPositionList = data.map(item => { return { label: item.code, value: item.id } })
        if(!params) this.deviceGoodsPositionlist = data.map(item => { return { label: item.code, value: item.id } })
      })
    },
    /**
     * 获取库位列表(生产库位)
     */
    getFactoryPositionList(params) {
      this.getSelectOptions('getFactoryPositionDictList', {...params, factoryPositionType: 'factoryPositionTypeProduce' }).then((list) => {
        this.factoryPositionList = list
        if(!params) this.factoryPositionlist = list
      })
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getSelectOptions(apiName, params) {
      const that = this
      return new Promise((resolve, reject) => {
        this.$httpService(this.$apiStore.base(apiName), params)
          .then(({ data, code }) => {
            if (code !== 0) {
              reject()
              return
            }
            const recordList = data || []
            const list = recordList.map((item) => {
              if (item.containerTypeCode) {
                return {
                  label: that.$t(item.containerTypeName),
                  value: item.id,
                  containerTypeCode: item.containerTypeCode,
                  containerTypePattern: item.containerTypePattern,
                  containerTypePatternType: item.containerTypePatternType
                }
              }
              if (item.factoryPositionCode) {
                return {
                  label: item.factoryPositionName,
                  value: item.id,
                  factoryPositionCode: item.factoryPositionCode,
                  factoryPositionType: item.factoryPositionType,
                  freezeFlag: item.freezeFlag
                }
              }
              return {
                label: that.$t(item.name || item.code),
                value: item.id
              }
            })
            resolve(list)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取周转容器类型
    async getTurnoverContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternBarrel', 'containerPatternCrate']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.turnoverContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 获取周转容器编码
    async getTurnoverContainerCodeList(val) {
      let params = {}
      val ? params.containerTypeIds = [val] : params.containerTypePatterns = ['containerPatternBarrel', 'containerPatternCrate']
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.turnoverContainerCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
      if(!val){ this.turnoverContainerCodelist = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')}
    },
    // 获取移动容器类型
    async getMoveContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternShelf', 'containerPatternTray', 'containerPatternRobotComponents']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.moveContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryClasifyByCodeOrName'), {...params, level: 2 })
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
      if(!params) this.materialClassifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryMaterialOption'),params)
      if (code) return
      this.materialCodeList = data.map(item => { return { label: item.materialCode, value: item.id } })
      if(!params) this.materialCodelist = data.map(item => { return { label: item.materialCode, value: item.id } })
    },
    /**
     * 一级分类-二级分类-物料编码级联
     * 一级级联二级和物料编码
     */
     handleClassifyFirstChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.materialSecondClassifyId = ''
      formData.materialId = ''
      const params = formData.materialFirstClassifyId ? { parentId: formData.materialFirstClassifyId } : {}
      const materialParams = formData.materialFirstClassifyId ? { classifyFirst: formData.materialFirstClassifyId } : {}
      this.getClassifyByFirstClassify(params)
      this.getMaterialCodeList(materialParams)
    },
    /**
     *二级级联--物料编码
     */
     handleClassifySecondChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.materialId = ''
      const materialParams = {}
      formData.materialFirstClassifyId ? materialParams.classifyFirst = formData.materialFirstClassifyId : ''
      formData.materialSecondClassifyId ? materialParams.classifySecond = formData.materialSecondClassifyId : ''
      this.getMaterialCodeList(materialParams)
    },
    //由于已联调，针对机器人货位策略写的方法
    /**
     * 一级级联二级和物料编码
     */
    onClassifyFirstChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.materialSecondClassify = ''
      formData.materialCode = ''
      const params = formData.materialFirstClassify ? { parentId: formData.materialFirstClassify } : {}
      const materialParams = formData.materialFirstClassify ? { classifyFirst: formData.materialFirstClassify } : {}
      this.getClassifyByFirstClassify(params)
      this.getMaterialCodeList(materialParams)
    },
    /**
     *二级级联--物料编码
     */
    onClassifySecondChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.materialCode = ''
      const materialParams = {}
      formData.materialFirstClassify ? materialParams.classifyFirst = formData.materialFirstClassify : ''
      formData.materialSecondClassify ? materialParams.classifySecond = formData.materialSecondClassify : ''
      this.getMaterialCodeList(materialParams)
    },
    /**
     * 所属工厂 change(更多查询的联动)
     * 工厂-车间
     */
     handleFactoryChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.workshopId = ''
      formData.warehouseId = ''
      formData.warehouseAreaId = ''
      formData.areaId = ''
      const params = formData.factoryId ? { factoryId: formData.factoryId } : {}
      this.getWorkshopList(params)
      this.getWarehouseList(params)
      this.getWarehouseAreaList()
      this.getAreaList(params)
      this.getProductionLineList(params)
      this.getDeviceList(params)
      this.getDeviceGoodsPositionList(params)
    },
    /**
     * 所属车间 change
     * 车间-库区和区域
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = ''
      const params = formData.workshopId ? { workshopId: formData.workshopId } : {}
      this.getWarehouseAreaList(params)
      this.getAreaList(params)
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = ''
      const params = formData.warehouseId ? { warehouseId: formData.warehouseId } : {}
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属区域 change
     * 区域-产线
     * 区域-生产库位
     */
    handleAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.productionLineId = ''
      formData.productionFactoryPositionId = ''
      const params = formData.areaId ? { areaId: formData.areaId } : {}
      this.getFactoryPositionList(params)
      this.getProductionLineList(params)
    },
    /**
     * 库区 change
     * 库区
     */
    handleWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
    },
    /**
     * 所属产线 change
     * 产线-设备
     */
    handleProductionLineChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.deviceId = ''
      const params = formData.productionLineId ? { productionLineId: formData.productionLineId } : {}
      this.getDeviceList(params)
    },
    /**
     * 所属设备 change
     * 设备-设备货位
     */
    handleDeviceChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.deviceGoodsAllocationId = ''
      const params = formData.deviceId ? { deviceId: formData.deviceId } : {}
      this.getDeviceGoodsPositionList(params)
    }
  }
}
